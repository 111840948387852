<template>
  <ListingLayout
    :is-loading="isLoading"
    :meta="meta"
    :headers="headers"
    :items="list"
    no-filters
    :filter-names="[]"
    @filterOption="onFiltersChange"
    @clickOnRow="whenRowSelect"
  >
    <template v-slot:booking_status="{ item }">
      <div>
        <status-badge
          :status="item.booking_status"
          :text="item.booking_status"
        />
      </div>
    </template>
    <!-- asdadasd
  <v-data-table
    :loading="isLoading"
    :headers="headers"
    :items="list"
    :items-per-page="5"
  /> -->
  </ListingLayout>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    StatusBadge: () => import("@/components/base/Status-Badge.vue"),
  },
  mixins: [ListingLayoutMixins],
  data() {
    return {
      headers: [
        {
          text: "Booking Id",
          align: "start",
          sortable: true,
          value: "booking_id",
        },
        {
          text: "User",
          align: "start",
          sortable: false,
          value: "customer_email",
        },
        {
          text: "Station",
          align: "start",
          sortable: false,
          value: "location_name",
        },
        {
          text: "Time",
          align: "start",
          sortable: false,
          value: "start_time_format",
        },
        {
          text: "Duration",
          align: "start",
          sortable: false,
          value: "duration",
        },
        {
          text: "Energy",
          align: "start",
          sortable: false,
          value: "energy",
        },
        {
          text: "Revenue",
          align: "start",
          sortable: false,
          value: "revenue",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "booking_status",
        },
        // {
        //   text: "Actions",
        //   align: "start",
        //   sortable: false,
        //   value: "user",
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsBookings/getLoading",
      list: "csmsBookings/getList",
    }),
    meta() {
      return {
        page: 1,
        itemsPerPage: 10000,
        lastPage: 1,
        totalItems: this.list?.length,
      };
    },
  },
  methods: {
    whenRowSelect(v) {
      this.$emit("clickOnRow", v);
    },
  },
};
</script>
